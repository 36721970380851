import { Location } from 'history';
import {
    BazaarCounts,
    HomePageState,
    StrollerBrandOption,
} from 'mk2/apps/home/containers/Home/Home.reducers';
import { Count } from 'mk2/components/NotificationsBadge';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';

///////////////////////////////////////////////////////////////////
// Homepage load

export const HOME_LOAD_TRIGGER = 'HOME_LOAD_TRIGGER';
export interface HomeLoadTriggerAction {
    readonly type: typeof HOME_LOAD_TRIGGER;
    readonly currentUrl: Location;
}
export const HOME_LOAD_REQUEST = 'HOME_LOAD_REQUEST';
export interface HomeLoadRequestAction extends APIRequestAction {
    readonly type: typeof HOME_LOAD_REQUEST;
    readonly brandDaysCampaign: string;
}
export interface HomeLoadNormalizedResponse extends NormalizedResponse {
    readonly result: {
        forum: { posts: number[]; };
        brandDays: { posts: number[]; campaign: number; };
        contests: { posts: number[]; topic: number; };
        photoblog: { posts: number[]; };
        homepage: { posts: number[]; nextPage: number | null; };
        counselling: { counsellings: number[]; };
        strolleropedia: { strollers: number[]; };
        strollerbase: { numStrollers: number; };
        eshopPills: { eshopPills: string[] };
        bazaar: { counts: BazaarCounts; }
        lastVisitCountFriends: Count | '' | null;
        place: HomePageState['place'];
        strollerArticles: number[];
        strollerReviews: number[];
        reviewsStrollers: number[];
        wikiExperiences: number[];
        strollers: number[];
        strollersPreviewPhotoMap: Record<number, number>;
        strollersPhotos: number[];
    };
}
export const HOME_LOAD_SUCCESS = 'HOME_LOAD_SUCCESS';
export interface HomeLoadSuccessAction extends APISuccessAction<HomeLoadNormalizedResponse> {
    readonly type: typeof HOME_LOAD_SUCCESS;
}
export const HOME_LOAD_FAILURE = 'HOME_LOAD_FAILURE';
export interface HomeLoadFailureAction extends APIFailureAction {
    readonly type: typeof HOME_LOAD_FAILURE;
}

export const homeLoadTrigger =
    (currentUrl: Location): HomeLoadTriggerAction => ({
        type: HOME_LOAD_TRIGGER, currentUrl,
    });
export const homeLoadApi = {
    request: (brandDaysCampaign: string): HomeLoadRequestAction => ({
        type: HOME_LOAD_REQUEST, brandDaysCampaign,
    }),
    success: (response: HomeLoadNormalizedResponse): HomeLoadSuccessAction => ({
        type: HOME_LOAD_SUCCESS, response,
    }),
    failure: (error: any): HomeLoadFailureAction => ({
        type: HOME_LOAD_FAILURE, error,
    }),
};

///////////////////////////////////////////////////////////////////
// Homepage load next page

export const HOME_LOAD_PAGE_TRIGGER = 'HOME_LOAD_PAGE_TRIGGER';
export interface HomeLoadPageTriggerAction {
    readonly type: typeof HOME_LOAD_PAGE_TRIGGER;
    readonly page: number;
}
export const HOME_LOAD_PAGE_REQUEST = 'HOME_LOAD_PAGE_REQUEST';
export interface HomeLoadPageRequestAction extends APIRequestAction {
    readonly type: typeof HOME_LOAD_PAGE_REQUEST;
    readonly page: number;
}
export interface HomeLoadPageNormalizedResponse extends NormalizedResponse {
    readonly result: {
        posts: number[];
        nextPage: number | null;
    };
}
export const HOME_LOAD_PAGE_SUCCESS = 'HOME_LOAD_PAGE_SUCCESS';
export interface HomeLoadPageSuccessAction extends APISuccessAction {
    readonly type: typeof HOME_LOAD_PAGE_SUCCESS;
    readonly page: number;
}
export const HOME_LOAD_PAGE_FAILURE = 'HOME_LOAD_PAGE_FAILURE';
export interface HomeLoadPageFailureAction extends APIFailureAction {
    readonly type: typeof HOME_LOAD_PAGE_FAILURE;
    readonly page: number;
}

export const homeLoadPageTrigger =
    (page: number): HomeLoadPageTriggerAction => ({
        type: HOME_LOAD_PAGE_TRIGGER, page,
    });
export const homeLoadPageApi = {
    request: (page: number): HomeLoadPageRequestAction => ({
        type: HOME_LOAD_PAGE_REQUEST, page,
    }),
    success: (page: number, response: HomeLoadPageNormalizedResponse): HomeLoadPageSuccessAction => ({
        type: HOME_LOAD_PAGE_SUCCESS, page, response,
    }),
    failure: (page: number, error: any): HomeLoadPageFailureAction => ({
        type: HOME_LOAD_PAGE_FAILURE, page, error,
    }),
};

///////////////////////////////////////////////////////////////////
// Homepage load stroller brands

export interface HomeStrollersLoadBrandsNormalizedResponse extends NormalizedResponse {
    readonly result: {
        brands: StrollerBrandOption[];
    };
}
