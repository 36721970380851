import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import {
    ESHOP_PILLS_ACCESSORIES,
    ESHOP_PILLS_CARSEATS,
    ESHOP_PILLS_STROLLERS,
    HOME_ESHOP_PILLS_MAIN,
} from 'mk/autogenerated/translations/EshopPills.4432d6e79456c5770e60bc3ba54cc10f'
import { ACTIVE_SERVER_ID, ESHOP_URL } from 'mk/settings';
import { CategoryCollectionPill, EshopPillEntity } from 'mk2/apps/home/schemas';
import { Img } from 'mk2/components/Img';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { Panel } from 'mk2/components/Panel';
import { PanelHeading } from 'mk2/components/PanelHeading';
import React from 'react';
import styles from './EshopPills.mscss';


interface OwnProps {
    className?: string;
    eshopPills: EshopPillEntity[];
}

enum ProductSortType {
    COLLECTION = 'collection',
    CATEGORY = 'category',
}

const PILLS_MENU_STROLLERS = 'UGlsbHNNZW51OjI2';
const PILLS_MENU_CARSEATS = 'UGlsbHNNZW51OjI3';
const PILLS_MENU_ACCESSORIES = 'UGlsbHNNZW51OjI4';

const channelLocale = {
    201: 'sk-eur/sk/',
    202: 'cz-czk/cs/',
}[ACTIVE_SERVER_ID];

const utmParameters = '?utm_source=modrykonik&utm_medium=box_na_titulke&utm_campaign=mk_hp_eshop';

const fullEshopUrl = `${ESHOP_URL}${channelLocale}`;
const eshopUtmUrl = `${fullEshopUrl}${utmParameters}`;

class EshopPillsComponent extends React.PureComponent<OwnProps> {

    public render() {
        const { className, eshopPills } = this.props;

        const strollerPills = eshopPills.find((pill) => pill.gid === PILLS_MENU_STROLLERS);
        const carseeatPills = eshopPills.find((pill) => pill.gid === PILLS_MENU_CARSEATS);
        const accessoryPills = eshopPills.find((pill) => pill.gid === PILLS_MENU_ACCESSORIES);

        return (
            <Panel className={cx(styles.Strollers, className)}>
                <PanelHeading
                    title={HOME_ESHOP_PILLS_MAIN}
                    url={eshopUtmUrl}
                />
                <div>
                    <Link to={`${fullEshopUrl}category/strollers/${utmParameters}`}>
                        {ESHOP_PILLS_STROLLERS}
                    </Link>
                    <div className={styles.EshopPills__grid}>
                        {strollerPills && (
                            <>
                                {strollerPills.collectionPills &&
                                     strollerPills.collectionPills.length > 0 &&
                                     strollerPills.collectionPills.map((pill, i) => (
                                        <EshopPill key={i} pill={pill} sortType={ProductSortType.COLLECTION}/>
                                ))}
                                {strollerPills.categoryPills &&
                                     strollerPills.categoryPills.length > 0 &&
                                     strollerPills.categoryPills.map((pill, i) => (
                                        <EshopPill key={i} pill={pill} sortType={ProductSortType.CATEGORY}/>
                                ))}
                            </>
                        )}
                    </div>

                    <Link to={`${fullEshopUrl}category/car-seats/${utmParameters}`}>
                        {ESHOP_PILLS_CARSEATS}
                    </Link>
                    <div className={styles.EshopPills__grid}>
                        {carseeatPills && (
                            <>
                                {carseeatPills && carseeatPills.collectionPills &&
                                    carseeatPills.collectionPills.length > 0 &&
                                    carseeatPills.collectionPills.map((pill, i) => (
                                        <EshopPill key={i} pill={pill} sortType={ProductSortType.COLLECTION}/>
                                ))}
                                {carseeatPills && carseeatPills.categoryPills &&
                                    carseeatPills.categoryPills.length > 0 &&
                                    carseeatPills.categoryPills.map((pill, i) => (
                                        <EshopPill key={i} pill={pill} sortType={ProductSortType.CATEGORY}/>
                                ))}
                            </>
                        )}
                    </div>

                    <Link to={`${fullEshopUrl}category/accessories/${utmParameters}`}>
                        {ESHOP_PILLS_ACCESSORIES}
                    </Link>
                    <div className={styles.EshopPills__grid}>
                        {accessoryPills && (
                            <>
                                {accessoryPills.collectionPills &&
                                    accessoryPills.collectionPills.length > 0 &&
                                    accessoryPills.collectionPills.map((pill, i) => (
                                        <EshopPill sortType={ProductSortType.COLLECTION} key={i} pill={pill} />
                                    ))}

                                {accessoryPills.categoryPills &&
                                    accessoryPills.categoryPills.length > 0 &&
                                    accessoryPills.categoryPills.map((pill, i) => (
                                        <EshopPill key={i} pill={pill} sortType={ProductSortType.CATEGORY} />
                                    ))}
                            </>
                        )}
                    </div>
                </div>
            </Panel>
        );
    }
}

export default EshopPillsComponent;

interface EshopPillProps {
    pill: CategoryCollectionPill;
    sortType: ProductSortType;
}

export const EshopPill: React.FunctionComponent<EshopPillProps> = ({
    pill, sortType,
}) => (
     <Link
         className={styles.EshopPill__body}
         to={`${fullEshopUrl}${sortType}/${pill.slug}/${utmParameters}`}
     >
         {pill.imageUrl ? (
              <Img
                src={pill.imageUrl}
                height={40}
                width={40}
                className={styles.EshopPill__pillImage}
              />
            ) : (
             <div className="mr-1 flex items-center justify-center text-[2.5rem] sm:text-[3rem]">
                 <FontAwesomeIcon icon={faImage}/>
             </div>
            )
         }
          <span className={styles.EshopPill__name}>
              {pill.translation && pill.translation.name ? pill.translation.name : pill.name}
          </span>
        </Link>
);

EshopPill.displayName = 'EshopPill';

interface PlaceholderOwnProps {
    className: string;
}

export const EshopPillsPlaceholder: React.FunctionComponent<PlaceholderOwnProps> = ({
    className,
}) => (
    <Panel className={cx(styles.StrollersPlaceholder, className)}>
        <PanelHeading
            title={HOME_ESHOP_PILLS_MAIN}
            url={eshopUtmUrl}
        />
        <div>
            <Link
                className={styles.EshopPills__gridHeader}
                to={`${fullEshopUrl}category/strollers/${utmParameters}`}
            >
                {ESHOP_PILLS_STROLLERS}
            </Link>
            <div className={styles.EshopPills__grid}>
                <LoadingPlaceholder width={154} height={48} />
                <LoadingPlaceholder width={154} height={48} />
                <LoadingPlaceholder width={154} height={48} />
                <LoadingPlaceholder width={154} height={48} />
            </div>

            <Link
                className={styles.EshopPills__gridHeader}
                to={`${fullEshopUrl}category/car-seats/${utmParameters}`}
            >
                {ESHOP_PILLS_CARSEATS}
            </Link>
            <div className={styles.EshopPills__grid}>
                <LoadingPlaceholder width={154} height={48} />
                <LoadingPlaceholder width={154} height={48} />
                <LoadingPlaceholder width={154} height={48} />
                <LoadingPlaceholder width={154} height={48} />
            </div>

            <Link
                className={styles.EshopPills__gridHeader}
                to={`${fullEshopUrl}category/accessories/${utmParameters}`}
            >
                {ESHOP_PILLS_ACCESSORIES}
            </Link>
            <div className={styles.EshopPills__grid}>
                <LoadingPlaceholder width={154} height={48} />
                <LoadingPlaceholder width={154} height={48} />
                <LoadingPlaceholder width={154} height={48} />
                <LoadingPlaceholder width={154} height={48} />
            </div>
        </div>
    </Panel>
);

EshopPillsPlaceholder.displayName = 'EshopPillsPlaceholder';
